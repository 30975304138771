import { gql } from '@apollo/client';

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessage(
    $chat: String!,
    $input: SendMessageInput!
  ) {
    sendMessage(
      chat: $chat,
      input: $input
    )
    @rest(
      type: "SendMessageResponse",
      path: "chats/{args.chat}/messages",
      method: "POST"
    ) {
      data {
        user {
          id
          chat_id
          text
          type
          sender
          status
        }
        bot {
          id
          chat_id
          text
          type
          sender
          status
        }
      }
    }
  }
`;
