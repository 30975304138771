import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// Local
import ProfileBtn from '../ProfileBtn';
import logoImage from '../../images/biblum-logo-transparent-1.png';

type HeaderProps = {
  hideAppName?: boolean;
}

const HeaderPropTypes = {
  hideAppName: PropTypes.bool
};

const Header: FunctionComponent<HeaderProps> = ({
  hideAppName
}) => {
  const { t } = useTranslation();

  return (
    <AppBar
      position='sticky'
      elevation={0}
      sx={{backgroundColor: '#f5f5f5'}}
    >
      <Toolbar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '80%',
            mx: 'auto'
          }}
        >
          {!hideAppName && (
            <Box display='flex' alignItems='center'>
              <img src={logoImage} width='40rem'/>
              <Typography pl={'0.5rem'} variant='h6' component='div' color={'rgba(0 0 0 / 70%)'}>
                {t('home.title')}
              </Typography>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }}/>
          <ProfileBtn/>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = HeaderPropTypes;

export default memo(Header);
