// External imports
import { Routes, Route, type RouteObject } from 'react-router-dom';

// Local imports
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';
import ErrorBoundary from './components/ErrorBoundary';
import ContextProviders from './context/ContextProviders';
import {
  PATH_ROOT,
  PATH_HOME,
  PATH_LOGIN,
  PATH_SIGNUP,
  PATH_CHAT,
  PATH_CHATS,
  PATH_RELATED_VERSES,
  PATH_CONFIRM_EMAIL,
  PATH_SHARED_CHAT
} from './config/paths';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import SignupPage from './pages/Signup';
import ChatPage from './pages/Chat';
import VersesPage from './pages/Verses';
import ConfirmEmail from './pages/ConfirmEmail';
import SharedChat from './pages/SharedChat';

const routes: RouteObject[] = [
  { path: PATH_ROOT, element: <HomePage/> },
  { path: PATH_HOME, element: <HomePage/> },
  { path: PATH_LOGIN, element: <LoginPage/> },
  { path: PATH_SIGNUP, element: <SignupPage/> },
  { path: PATH_CHAT, element: <ChatPage/> },
  { path: PATH_CHATS, element: <ChatPage/> },
  { path: PATH_RELATED_VERSES, element: <VersesPage/> },
  { path: PATH_CONFIRM_EMAIL, element: <ConfirmEmail/>},
  { path: PATH_SHARED_CHAT, element: <SharedChat/>},
  { path: '*', element: <HomePage/> }
];

// Component definition
function App() {
  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={(
              <ErrorBoundary fallback={<ErrorBoundaryFallback/>}>
                <ContextProviders>
                  {element}
                </ContextProviders>
              </ErrorBoundary>
            )}
        />
      ))}
    </Routes>
  );
}

// Default export
export default App;
