import { gql } from '@apollo/client';

export const DELETE_SHARED_LINK_MUTATION = gql`
  mutation DeleteSharedLink(
    $chat: String!
    $share: String!
  ) {
    deleteSharedLink(
      chat: $chat
      share: $share
    )
    @rest(
      type: "DeleteSharedLinkResponse",
      path: "chats/{args.chat}/share/{aregs.share}",
      method: "DELETE"
    ) {
      success
    }
  }
`;
