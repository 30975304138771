import { gql } from '@apollo/client';

export const RELATED_VERSES_QUERY = gql`
  query RelatedVerses(
    $verse: String!
  ) {
    relatedVerses(
      verse: $verse
    )
    @rest(
      type: "RelatedVersesPayload",
      path: "verses/{args.verse}"
    ) {
      data {
        verse {
          id
          number
          text
          chapter {
            id
            number
          }
          book {
            id
            title
          }
        }
        related {
          id
          number
          text
          chapter {
            id
            number
          }
          book {
            id
            title
          }
        }
      }
    }
  }
`;
