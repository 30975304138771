import {
  memo, useState, useCallback,
  type KeyboardEvent, type ChangeEvent, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import size from 'lodash/size';
import trim from 'lodash/trim';
// MUI
import TextareaAutosize from '@mui/material/TextareaAutosize';
// Local
import SendBtn from './SendBtn';
import style from './MessageInput.module.scss';

type MessageInputProps = {
  onSendMessage?: (message: string) => void;
  pending?: boolean;
  disabled?: boolean;
}

const MessageInputPropTypes = {
  onSendMessage: PropTypes.func,
  pending: PropTypes.bool,
  disabled: PropTypes.bool
};

const MessageInput: FunctionComponent<MessageInputProps> = ({
  onSendMessage,
  pending,
  disabled
}) => {
  const { t } = useTranslation();
  const { textarea_container } = style;
  const [inputDisabled, setInputDisabled] = useState(true);
  const [message, setMessage] = useState<string>('');

  const handleMessageChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setMessage(toString(event.target.value));
    setInputDisabled(size(trim(toString(event.target.value))) === 0);
  }, []);

  const handleSendMessage = useCallback(() => {
    if(size(trim(message))) {
      onSendMessage?.(toString(message));
      setMessage('');
      setInputDisabled(true);
    }
  }, [message, onSendMessage]);

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  }, [handleSendMessage]);

  const placeholder = disabled ? 'send.message.disabled.label' : 'send.message.label';

  return (
    <div className={textarea_container}>
      <TextareaAutosize
        aria-label="minimum height"
        placeholder={toString(t(placeholder))}
        autoComplete='off'
        value={message}
        onChange={handleMessageChange}
        onKeyPress={handleKeyPress}
        disabled={pending || disabled}
        minRows={1}
        maxRows={7}
      />
      <SendBtn
        pending={pending}
        disabled={disabled || inputDisabled}
        onClick={handleSendMessage}
      />
    </div>
  );
};

MessageInput.propTypes = MessageInputPropTypes;

export default memo(MessageInput);
