import { PureComponent, type ReactNode } from 'react';

interface Props {
  children: React.ReactNode;
  fallback: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends PureComponent<Props, State> {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  state = {
    hasError: false
  };

  // componentDidCatch(/* error, info */) {
  //   ...additional error handling...
  // }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;
    return (hasError ? fallback || <div></div> : children) as ReactNode;
  }
}

export default ErrorBoundary;
