// import capitalize from 'lodash/capitalize';
import { InMemoryCache } from '@apollo/client';
// local imports
import { StrictTypedTypePolicies } from './types';

const typePolicies: StrictTypedTypePolicies = {};

export const createCache = () => new InMemoryCache({
  typePolicies
});

export const cache = createCache();
