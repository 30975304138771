import { memo, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
// MUI
import Box from '@mui/material/Box';
// Local
import { type MessageRefs, type RippleRef } from './types';
import CustomTouchRipple from './CustomTouchRipple';
import style from './UserMessage.module.scss';

type UserMessageProps = {
  text?: string;
  index?: number;
  messageRefs?: MessageRefs;
}

const UserMessagePropTypes = {
  text: PropTypes.string,
  index: PropTypes.number,
  messageRefs: PropTypes.object as Validator<MessageRefs>,
};

const UserMessage: FunctionComponent<UserMessageProps> = ({
  text,
  index,
  messageRefs
}) => {
  return (
    <Box className={style['message-body']}>
      {text}
      <CustomTouchRipple
        ref={messageRefs?.current?.[`ripple${index}`] as RippleRef}
      />
    </Box>
  );
};

UserMessage.propTypes = UserMessagePropTypes;

export default memo(UserMessage);
