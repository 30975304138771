import { gql } from '@apollo/client';

export const SCRIPTURES_QUERY = gql`
  query Scriptures {
    scriptures
    @rest(
      type: "ScripturesPayload",
      path: "religions"
    ) {
      data {
        key
        religion
        bible
      }
    }
  }
`;
