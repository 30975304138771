import { memo, useEffect, useState, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import size from 'lodash/size';
import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
// MUI
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// Local
import { useApi } from '../context/ApiProvider';

type ScripturesSelectProps = {
  onSelect?: (id: string) => void;
  disabled?: boolean;
}

const ScripturesSelectPropTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool
};

const ScripturesSelect: FunctionComponent<ScripturesSelectProps> = ({
  onSelect,
  disabled = false
}) => {
  const { t } = useTranslation();
  const {
    getSources: { getSources, pending, failed, sources },
    getSettings: { getSettings, pending: getPending, settings },
    setSettings: { setSettings, pending: setPending }
  } = useApi();
  const [source, setSource] = useState<string>('');

  useEffect(() => {
    if(isNil(sources)) getSources?.();
  }, [sources, getSources]);

  useEffect(() => {
    if(isNil(settings)) getSettings?.();
  }, [settings, getSettings]);

  const handleChange = (event: SelectChangeEvent) => {
    const newSource = toString(event.target.value);
    setSource(newSource);
    setSettings?.({ scripture: newSource });
  };

  useEffect(() => onSelect?.(source), [source, onSelect]);

  useEffect(() => {
    if (!pending && !failed && sources && (size(sources) > 0) && !isNil(settings)) {
      const saved = sources.find(item => item.key === settings.scripture);
      if (saved) setSource(saved.key);
    }
  }, [sources, pending, failed, settings]);

  return (
    <Box sx={{ minWidth: 120 }} px={1}>
      <FormControl fullWidth>
        <InputLabel
          id="source-select-label"
          size="small"
        >
          {t('scripture')}
        </InputLabel>
        <Select
          labelId="source-select-label"
          id="source-select"
          value={source}
          label={t('scripture')}
          onChange={handleChange}
          disabled={disabled || pending || failed || getPending || setPending}
          size="small"
        >
          {map(sources, ({key, religion, bible}) => (
            <MenuItem key={key} value={key}>{religion} : {bible}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

ScripturesSelect.propTypes = ScripturesSelectPropTypes;

export default memo(ScripturesSelect);
