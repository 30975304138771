import { memo, type FunctionComponent } from 'react';
// Local imports
import Login from '../components/home/Login';

const LoginPage: FunctionComponent = () => {
  return (
    <Login/>
  );
}

export default memo(LoginPage);
