import { gql } from '@apollo/client';

export const UPDATE_CHAT_MUTATION = gql`
  mutation UpdateChat(
    $chat: String!,
    $input: UpdateChatInput!
  ) {
    updateChat(
      chat: $chat,
      input: $input
    )
    @rest(
      type: "UpdateChatResponse",
      path: "chats/{args.chat}",
      method: "PATCH"
    ) {
      data {
        id
        title
      }
    }
  }
`;
