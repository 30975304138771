import { gql } from '@apollo/client';

export const GET_SHARED_CHAT_QUERY = gql`
  query GetSharedChat(
    $chat: String!
    $share: String!
  ) {
    getSharedChat(
      chat: $chat
      share: $share
    )
    @rest(
      type: "SharedChatResponse",
      path: "chats/{args.chat}/share/{args.share}"
    ) {
      data {
        is_current
        created_at
        messages {
          id
          chat_id
          text
          type
          sender
          status
          verses {
            id
            number
            text
            chapter {
              id
              number
            }
            book {
              id
              title
            }
          }
        }
      }
    }
  }
`;
