import {
  memo, useMemo, useState, useCallback,
  useEffect, useRef, createRef, type FunctionComponent
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';
import toString from 'lodash/toString';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
// MUI
import Box from '@mui/material/Box';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
// Local imports
import { PATH_CHATS, injectParams } from '../config/paths';
import { useApi } from '../context/ApiProvider';
import {
  type MsgItemRefs,
  type MessageItemRefs,
  type MutableMessageRefs
} from '../components/types';
import { SendMessageResponse, CreateFirstChatResponse } from '../graphql/types';
import Screen from '../components/Screen';
import ChatHeader from '../components/ChatHeader';
import ChatDrawer from '../components/ChatDrawer';
import MessageInput from '../components/MessageInput';
import ChatHistory from '../components/ChatHistory';

type ChatPageProps = {
  testSendMessage?: boolean;
}

const ChatPagePropTypes = {
  testSendMessage: PropTypes.bool
};

const ChatPage: FunctionComponent<ChatPageProps> = ({
  testSendMessage
}) => {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [passedState, setPassedState] = useState(location.state);
  const itemRefs: MutableMessageRefs = useRef<MessageItemRefs>(null);
  const [source, setSource] = useState('');

  const {
    getChats: { getChats, refetch: refetchChats, chats, failed: chatsFailed },
    getHistory: { getHistory, refetch, pending, failed, history, previousParams },
    sendMessage: { sendMessage, pending: messageSendPending, failed: messageSendFailed },
    getMessage: { polling },
    getDrawer: { drawerOn },
    createFirstChat: { createFirstChat }
  } = useApi();

  const loading = Boolean(chatId) && (pending || messageSendPending || polling);
  const error = Boolean(chatId) && (failed || messageSendFailed);

  useEffect(() => {
    if(isUndefined(chats) && !chatsFailed) getChats?.();
  }, [chats, chatsFailed, getChats]);

  useEffect(() => {
    if (!isUndefined(chatId)) getHistory?.(chatId);
  }, [chatId, getHistory]);

  const historyItems = useMemo(() => {
    const { chat: prevChat } = previousParams || {};
    if (isUndefined(chatId) || toString(chatId) !== toString(prevChat) || pending) return undefined;
    if (history) {
      itemRefs.current = history.reduce((acc, _, index) => {
        acc[index] = createRef<HTMLLIElement>();
        acc[`ripple${index}`] = createRef<TouchRippleActions>();
        return acc;
      }, {} as MsgItemRefs);
    }
    return history;
  }, [chatId, history, previousParams, pending]);

  const onSelectSource = useCallback((sourceId: string) => {
    setSource(sourceId);
  }, []);

  const onSendMessage = useCallback((message: string) => {
    if(size(message) && sendMessage) {
      sendMessage({
        chat: isUndefined(chatId) ? 'new' : chatId,
        message,
        source,
        onCompleted: (data?: SendMessageResponse | null) => {
          const chat = data?.data.bot.chat_id;
          if (!isUndefined(chatId)) {
            refetchChats?.();
            refetch?.();
          } else if (chat) {
            refetchChats?.();
            navigate(injectParams(PATH_CHATS, {chatId: chat}), { replace: true });
          }
        }
      });
    }
  }, [chatId, sendMessage, source, refetch, refetchChats, navigate]);

  useEffect(() => {
    if (chats) {
      const message = passedState?.message;
      const signup = passedState?.signup;
      if (signup && size(chats) == 0) {
        createFirstChat?.({
          source,
          onCompleted: (data?: CreateFirstChatResponse | null) => {
            const chat = data?.data.id;
            if (chat) {
              navigate(injectParams(PATH_CHATS, {chatId: chat}), { replace: true });
            }
            refetchChats?.();
            setPassedState({message: message});
          }
        });
      } else {
        if (size(source) && size(message)) {
          setPassedState(null);
          onSendMessage(message);
        }
      }
    }
  }, [passedState, onSendMessage, source, createFirstChat, refetchChats, navigate, chats]);

  useEffect(() => {
    if(testSendMessage) sendMessage?.({ chat: '1', message: '', source: '' });
  }, [testSendMessage, sendMessage]);

  return (
    <Screen>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {/* Header */}
        <ChatHeader
          onSelectScripture={onSelectSource}
          pending={loading}
          showSources
          showShare={!isNil(chatId)}
        />

        {/* Main Content Area */}
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
          {/* Left Column */}
          <ChatDrawer
            chatId={chatId}
            drawerOn={drawerOn}
            history={historyItems}
            itemRefs={itemRefs}
          />

          {/* Right Column */}
          <ChatHistory
            chat={chatId}
            history={historyItems}
            itemRefs={itemRefs}
            pending={loading}
            failed={error}
          >
            {/* Footer */}
            <MessageInput
              onSendMessage={onSendMessage}
              pending={loading}
              disabled={!size(source)}
            />
          </ChatHistory>
        </Box>
      </Box>
    </Screen>
  );
}

ChatPage.propTypes = ChatPagePropTypes;

// Default export
export default memo(ChatPage);
