import { memo, useCallback, forwardRef, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// Local
// import { useApi } from '../context/ApiProvider';
// import FetchFailedAlert from '../components/FetchFailedAlert';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type SettingsDlgProps = {
  open: boolean;
  onClose?: () => void;
}

const SettingsDlgPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

const SettingsDlg: FunctionComponent<SettingsDlgProps> = ({
  open = false,
  onClose
}) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => onClose?.(), [onClose]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box
          display='flex'
          alignItems='center'
        >
          {t('settings.dlg.title')}
          <Box flexGrow='1'/>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon/>
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <DialogContentText>
          Set some settings here
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

SettingsDlg.propTypes = SettingsDlgPropTypes;

export default memo(SettingsDlg);
