import { memo, useMemo, useEffect, useState, useCallback, type FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
// Local
import { PATH_CHAT } from '../config/paths';
import { CONFIRM_EMAIL_MUTATION } from '../graphql/ConfirmEmail';
import { ConfirmEmailDocument } from '../graphql/types';
import useMutationMethod from '../hooks/useMutationMethod';
import logoImage from '../images/biblum-logo.png';

const ConfirmEmail: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [confirmed, setConfirmed] = useState(false);
  const [badToken, setBadToken] = useState(false);
  const token = useMemo(() => params.get('token'), [params]);

  const { mutate: confirmEmail, loading, failed, data } = useMutationMethod({
    mutation: useMutation(CONFIRM_EMAIL_MUTATION as typeof ConfirmEmailDocument)
  });

  useEffect(() => {
    if (token) {
      confirmEmail({ variables: { input: { token } } });
    }
  }, [token, confirmEmail]);

  useEffect(() => {
    if (data) {
      if (data.confirmEmail?.success) {
        setConfirmed(true);
        localStorage.setItem('token', toString(data.confirmEmail.data.auth_token));
      } else {
        setBadToken(true);
      }
    }
  }, [data]);

  const handleContinue = useCallback(() => navigate(PATH_CHAT), [navigate]);

  return (
    <Container>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid item xs={12}>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Box mr={2} my={2} ml={-6}>
              <img src={logoImage} width='50rem'/>
            </Box>
            <Typography variant="h3" component="div">
              {t('app.name')}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h4" component="div">
              {t('app.description')}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Box mt={5}>
            {
              (loading && (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <CircularProgress />
                  <Typography variant="h5" component='div' pl={1}>
                    {t('confirm.email.confirming')}
                  </Typography>
                </Box>
              )) ||
              (confirmed && (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='column'
                >
                  <Alert
                    severity="success"
                    sx={{
                          fontSize: '20px',
                          '& .MuiAlert-icon': {
                            fontSize: '30px',
                          },
                        }}
                  >
                    {t('confirm.email.success')}
                  </Alert>
                  <Box mt={5}>
                    <Button variant='contained' onClick={handleContinue}>
                      {t('confirm.email.continue')}
                    </Button>
                  </Box>
                </Box>
              )) ||
              ((failed || badToken) && (
                <Alert
                  severity="error"
                  sx={{
                        fontSize: '20px', // Change the font size
                        '& .MuiAlert-icon': {
                          fontSize: '30px', // Change the icon size
                        },
                      }}
                >
                  {t('confirm.email.bad-token')}
                </Alert>
              )) ||
              (<Typography variant="h5">{t('confirm.email.confirming')}</Typography>)
            }
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default memo(ConfirmEmail);
