import { memo, type FunctionComponent } from 'react';
// MUI
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// Local

const LoadingPlaceholder: FunctionComponent = () => {
  return (
    <Box sx={{ height: '10rem', width: '100%' }}>
      <Skeleton variant="rectangular" height={'10rem'}/>
    </Box>
  );
};

export default memo(LoadingPlaceholder);
