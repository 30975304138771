import { memo, useMemo, type ReactNode, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import size from 'lodash/size';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// Local
import { MIN_HISTORY } from '../config/params';
import LoadingPlaceholder from './LoadingPlaceholder';
import FetchFailedAlert from '../components/FetchFailedAlert';
import { ChatMessage } from '../graphql/types';
import NoHistoryBanner from './NoHistoryBanner';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';
import logoImage from '../images/biblum-logo.png';

type ListSharedChatProps = {
  history?: ChatMessage[];
  createdAt?: string;
  pending?: boolean;
  failed?: boolean;
  children?: ReactNode | ReactNode[];
}

const ListSharedChatPropTypes = {
  history: PropTypes.array as Validator<ChatMessage[]>,
  createdAt: PropTypes.string,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const ListSharedChat: FunctionComponent<ListSharedChatProps> = ({
  history,
  createdAt,
  pending,
  failed,
  children
}) => {
  const { t } = useTranslation();
  const { chatId } = useParams();

  const formattedDate = useMemo(() => {
    if (!createdAt) return null;
    const date = new Date(Number(createdAt)*1000);
    return format(date, 'yyyy-MM-dd');
  }, [createdAt]);

  return (
    <Box
      sx={{
        width: '90%',
        minWidth: `${MIN_HISTORY}rem`,
        mx: 'auto',
        overflow: 'scroll'
      }}
    >
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      >
        <Grid item xs={12}>
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <Box m={2} ml={-4}>
              <img src={logoImage} width='30rem'/>
            </Box>
            <Typography variant="h4" component="div">
              {t('app.name')}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h6" component="div">
              {t('app.description')}
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" py={'2rem'}>
            <Box
              display='flex'
              flexDirection='column'
              sx={{width: '55%'}}
            >
              <Box
                sx={{width: 'fit-content', mx: 'auto'}}
              >
                <Typography variant="h4" component="div">
                  {t('shared.chat.title')}
                </Typography>
              </Box>
              <Divider/>
              {formattedDate && (
                <Typography
                  variant="body1"
                  component="div"
                  color='rgba(0 0 0 / 50%)'
                  textAlign='right'
                >
                  {formattedDate}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box
        display='flex'
        flexGrow={1}
        flexDirection='column'
        overflow='hidden'
        my={0}
        px={1}
        style={{ minWidth: `${MIN_HISTORY}rem` }}
      >
        {((size(history) || pending || failed) && (
          <>
            <List sx={{ overflow: 'scroll' }}>
              {map(history, (item, index) => (
                <ListItem key={index}>
                  {((item.sender === 'Bot') && (
                    <BotMessage
                      id={item.id}
                      chat={chatId}
                      text={item.text}
                      status={item.status}
                      verses={item.verses}
                    />
                  )) || (
                    <UserMessage
                      text={item.text}
                      index={index}
                    />
                  )}
                </ListItem>
              ))}
              {failed && (
                <ListItem>
                  <FetchFailedAlert/>
                </ListItem>
              )}
              {pending && (
                <ListItem>
                  <LoadingPlaceholder/>
                </ListItem>
              )}
            </List>
          </>
        )) || (
          <NoHistoryBanner/>
        )}
        <Box sx={{ position: 'relative', display: 'flex', flexShrink: 0 }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

ListSharedChat.propTypes = ListSharedChatPropTypes;

export default memo(ListSharedChat);
