import { gql } from '@apollo/client';

export const DELETE_CHAT_MUTATION = gql`
  mutation DeleteChat(
    $chat: String!
  ) {
    deleteChat(
      chat: $chat
    )
    @rest(
      type: "DeleteChatResponse",
      path: "chats/{args.chat}",
      method: "DELETE"
    ) {
      success
    }
  }
`;
