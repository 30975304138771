import { forwardRef, ReactNode } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/system';

const GradientText = styled('span')({
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  // textOverflow: 'ellipsis',
  background: 'linear-gradient(to right, rgba(0, 0, 0, 0.65) 90%, transparent 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textAlign: 'left',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: '400'
});

interface FadingTextButtonProps extends ButtonProps {
  children: ReactNode;
}

const FadingTextButton = forwardRef<HTMLButtonElement, FadingTextButtonProps>(({ children, ...props }, ref) => {
  return (
    <Button ref={ref} {...props}>
      <GradientText>{children}</GradientText>
    </Button>
  );
});

FadingTextButton.displayName = 'FadingTextButton';

export default FadingTextButton;
