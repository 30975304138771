import { gql } from '@apollo/client';

export const LIST_SHARED_LINKS_QUERY = gql`
  query ListSharedLinks(
    $chat: String!
  ) {
    listSharedLinks(
      chat: $chat
    )
    @rest(
      type: "ListShareLinksPayload",
      path: "chats/{args.chat}/share"
    ) {
      data {
        id
      }
    }
  }
`;
