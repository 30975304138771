import { gql } from '@apollo/client';

export const GUEST_AUTH_MUTATION = gql`
  mutation GuestAuth($input: EmptyInput!) {
    guestAuth(input: $input)
    @rest(
      type: "SignupResponse",
      path: "auth/guest",
      method: "POST"
    ) {
      success
      data {
        user {
          id
          email
        }
        tokens {
          access_token
        }
      }
    }
  }
`;
