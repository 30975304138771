import { gql } from '@apollo/client';

export const JESUS_SAID_QUERY = gql`
  query JesusSaid(
    $verse: String!
  ) {
    jesusSaid(
      verse: $verse
    )
    @rest(
      type: "JesusSaidPayload",
      path: "verses/{args.verse}/jesus_commentary"
    ) {
      data {
        text
      }
    }
  }
`;
