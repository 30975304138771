import { memo, useEffect, type ReactNode, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
// local imports
// import useMutationMethod from '../hooks/useMutationMethod';
import { GUEST_AUTH_MUTATION } from '../graphql/GuestAuth';
import { GuestAuthMutation, GuestAuthDocument } from '../graphql/types';
import { isLoggedIn } from '../helpers/user';

type ScreenProps = {
  children?: ReactNode | ReactNode[];
};

const ScreenPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const Screen: FunctionComponent<ScreenProps> = ({
  children
}) => {
  const [guestAuth] = useMutation(GUEST_AUTH_MUTATION as typeof GuestAuthDocument);

  useEffect(() => {
    if (!isLoggedIn() && guestAuth) {
      guestAuth({
        variables: { input: { empty: '' } },
        onCompleted: (data: GuestAuthMutation) => {
          const token = data.guestAuth.data.tokens.access_token
          if (token) {
            localStorage.setItem('token', token);
            localStorage.setItem('role', 'guest');
          }
        }
      });
    }
  }, [guestAuth]);

  return (
    <>
      {children}
    </>
  );
};

Screen.propTypes = ScreenPropTypes;

export default memo(Screen);
