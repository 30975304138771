import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input)
    @rest(
      type: "LoginResponse",
      path: "accounts/login",
      method: "POST"
    ) {
      success
      data {
        id
        email
        auth_token
      }
    }
  }
`;
