import { type FunctionComponent } from 'react';
// SCSS imports
import styles from './ErrorBoundaryFallback.module.scss';

const ErrorBoundaryFallback: FunctionComponent = () => (
  /* eslint-disable react/jsx-no-literals */
  /* eslint-disable react/jsx-one-expression-per-line */
  <div className={styles.root}>
    <div>
      <strong>
        We&apos;re sorry – something&apos;s gone wrong.
      </strong>
    </div>
    <div>
      To continue please try to reload this page in your browser or
      start the nagivation from the <a href="/">Home page</a>.
    </div>
  </div>
  /* eslint-enable react/jsx-one-expression-per-line */
  /* eslint-enable react/jsx-no-literals */
);

export default ErrorBoundaryFallback;
