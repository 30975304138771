import toString from 'lodash/toString';
import toNumber from 'lodash/toNumber';

export const setSPos = (key: string, pos?: number): void => {
  localStorage.setItem(`${key}-scroll`, toString(pos));
};

export const getSPos = (key: string): number => {
  return toNumber(localStorage.getItem(`${key}-scroll`));
};
