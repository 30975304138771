import { gql } from '@apollo/client';

export const VERSE_COMMENTARIES_QUERY = gql`
  query VerseCommentaries(
    $verse: String!
  ) {
    verseCommentaries(
      verse: $verse
    )
    @rest(
      type: "VerseCommentariesPayload",
      path: "verses/{args.verse}/commentaries"
    ) {
      data {
        source
        text
      }
    }
  }
`;
