import { memo, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// Local
import { PATH_SIGNUP } from '../../config/paths';
import style from './SignupBtn.module.scss';

type SignupBtnProps = {
  disabled?: boolean;
}

const SignupBtnPropTypes = {
  disabled: PropTypes.bool
};

const SignupBtn: FunctionComponent<SignupBtnProps> = ({
  disabled
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = useCallback(() => navigate(PATH_SIGNUP), [navigate]);

  return (
    <Box className={style['container']} pr={'0.5rem'}>
      <Button
        disabled={disabled}
        onClick={onClick}
      >
        {t('signup')}
      </Button>
    </Box>
  );
};

SignupBtn.propTypes = SignupBtnPropTypes;

export default memo(SignupBtn);
