import {
  memo, useState, useCallback, useEffect,
  type KeyboardEvent, type ChangeEvent, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import size from 'lodash/size';
import trim from 'lodash/trim';
// MUI
import TextareaAutosize from '@mui/material/TextareaAutosize';
// Local
import { PATH_CHAT } from '../../config/paths';
import InputBtn from './InputBtn';
import style from './MessageInput.module.scss';
import './rotate.css';

type MessageInputProps = {
  pending?: boolean;
  disabled?: boolean;
}

const MessageInputPropTypes = {
  pending: PropTypes.bool,
  disabled: PropTypes.bool
};

const MessageInput: FunctionComponent<MessageInputProps> = ({
  pending,
  disabled
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { textarea_container } = style;
  const [inputDisabled, setInputDisabled] = useState(true);
  const [message, setMessage] = useState<string>('');

  const questions = [
    'app.about.text.5.1',
    'app.about.text.5.2',
    'app.about.text.5.3',
    'app.about.text.5.4'
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!size(message)) {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % questions.length);
      }, 6000); // Change text every 3 seconds
      return () => clearInterval(interval);
    }
    return undefined;
  }, [message, questions.length]);

  const getClass = useCallback((i: number) => {
    if(!size(message)) {
      if (i === index) return 'active';
      if (i === (index + 1) % questions.length) return 'down';
    }
    return '';
  }, [message, index, questions.length]);

  const handleMessageChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setMessage(toString(event.target.value));
    setInputDisabled(size(trim(toString(event.target.value))) === 0);
  }, []);

  const handleSendMessage = useCallback(() => {
    if (size(trim(message))) {
      navigate(PATH_CHAT, { state: { signup: true, message: message } });
      setMessage('');
      setInputDisabled(true);
    }
  }, [message, navigate]);

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  }, [handleSendMessage]);

  return (
    <div className={textarea_container}>
      <TextareaAutosize
        aria-label='minimum height'
        autoComplete='off'
        value={message}
        onChange={handleMessageChange}
        onKeyPress={handleKeyPress}
        disabled={pending || disabled}
        minRows={1}
        maxRows={7}
      />
      <InputBtn
        pending={pending}
        disabled={disabled || inputDisabled}
        onClick={handleSendMessage}
      />
      {questions.map((text, i) => (
        <div key={i} className={`text-slide ${getClass(i)}`}>
          {t(text)}
        </div>
      ))}
    </div>
  );
};

MessageInput.propTypes = MessageInputPropTypes;

export default memo(MessageInput);
