import { gql } from '@apollo/client';

export const SET_SETTINGS_MUTATION = gql`
  mutation SetSettings($input: SettingsInput!) {
    setSettings(input: $input)
    @rest(
      type: "SetSettingsResponese",
      path: "settings",
      method: "PATCH"
    ) {
      success
    }
  }
`;
