import { memo, useCallback, useRef, type FunctionComponent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Mousewheel } from 'swiper/modules';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
// local
import { ABOUT } from '../../config/about';
import AboutSlide from './AboutSlide';
import InputSlide from './InputSlide';
import './Carousel.css'

const Carousel: FunctionComponent = () => {
  const swiperRef = useRef<SwiperType | null>(null);

  const handleSwiper = useCallback((swiper: SwiperType) => {
    swiperRef.current = swiper;
  }, []);

  const handleSlideClick = useCallback((index: number) => {
    swiperRef.current?.slideTo(index);
  }, []);

  const wrapper = (element: React.ReactNode, background?: string, backgroundPosition?: string) => {
    return (
      <Box
        sx={{
          mb: '5rem',
          pt: '3rem',
          width: '100%',
          height: '100%',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            borderRadius: '0.5rem',
            backgroundImage: background ? `url(${background})` : undefined,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            backgroundPosition: backgroundPosition ? backgroundPosition : 'center',
            opacity: '0.95'
          }}
        >
          {element}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ height: '90vh', width: '100%', backgroundColor: '#f5f5f5' }}>
      <Swiper
        slidesPerView={'auto'}
        centeredSlides={true}
        spaceBetween={30}
        mousewheel={{forceToAxis: true}}
        pagination={{ clickable: true }}
        navigation
        modules={[Mousewheel, Pagination]}
        onSwiper={handleSwiper}
        className="mySwiper"
      >
        <SwiperSlide key={0} onClick={() => handleSlideClick(Number(0))}>
          {wrapper(<InputSlide />)}
        </SwiperSlide>
        {map(ABOUT,({title, text, background, blist, backgroundPosition}, index) => (
          <SwiperSlide key={index + 1} onClick={() => handleSlideClick(Number(index + 1))}>
            {wrapper(
              <AboutSlide
                title={title}
                text={text}
                blist={blist}
              />,
              background,
              backgroundPosition
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default memo(Carousel);
