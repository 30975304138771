import { memo, useMemo, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
// Local
import { PATH_CHATS, injectParams } from '../config/paths';

type ShowVersesHeaderProps = {
  disabled?: boolean;
}

const ShowVersesHeaderPropTypes = {
  disabled: PropTypes.bool
};

const ShowVersesHeader: FunctionComponent<ShowVersesHeaderProps> = ({
  disabled
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { chatId } = useParams();

  const path = useMemo(() => injectParams(PATH_CHATS, {chatId}), [chatId]);

  const handleClick = useCallback(() => navigate(path), [path, navigate]);

  return (
    <Box
      display='flex'
      alignItems='center'
    >
      <Typography
        component='div'
        sx={{
          pl: '1rem',
          pr: '0.5rem',
          fontSize: '1.3rem',
          fontWeight: '500',
          color: 'rgba(0 0 0 / 70%)'
        }}
      >
        {t('header.verse.explorer')}
      </Typography>
      <Tooltip title={t('tooltip.go.back.to.chat')} arrow>
        <IconButton onClick={handleClick} disabled={disabled}>
          <SpeakerNotesOutlinedIcon/>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

ShowVersesHeader.propTypes = ShowVersesHeaderPropTypes;

export default memo(ShowVersesHeader);
