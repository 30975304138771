import { type FunctionComponent, memo, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ApolloClient, ApolloProvider, from,
  type ServerError, type ServerParseError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/link-context';
import { typePatcher } from 'apollo-type-patcher';
// Material UI imports
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// local imports
import { ApiProvider } from './ApiProvider';
import { API_URL_V1 } from '../config/api';
import { typeDefinitions } from '../graphql/typeDefinitions';
import { cache } from '../graphql/cache';
import { getAuthToken, isLoggedIn, logout } from '../helpers/user';

type ContextProvidersProps = {
  children?: ReactNode | ReactNode[];
};

const ContextProvidersPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

// set API V1 endpoints root URL
const restLink = new RestLink({
  uri: API_URL_V1,
  typePatcher: typePatcher(typeDefinitions),
  credentials: 'same-origin'
});

// Middleware to dynamically set headers
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...(isLoggedIn() && {Authorization: `Token ${getAuthToken()}`})
    }
  };
});

// global error handling
const errorLink = onError(({ networkError }) => {
  if ((networkError as (ServerParseError | ServerError))?.statusCode === 401) logout();
});

// Apollo Client setup
const client = new ApolloClient({
  link: from([errorLink, authLink, restLink]),
  cache,
  name: process.env.REACT_APP_PACKAGE_NAME,
  version: process.env.REACT_APP_PACKAGE_VERSION
});

const ContextProviders: FunctionComponent<ContextProvidersProps> = ({
  children
}) => (
  <ApolloProvider client={client}>
    <ApiProvider>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        {children}
      </LocalizationProvider>
    </ApiProvider>
  </ApolloProvider>
);

ContextProviders.propTypes = ContextProvidersPropTypes;

export default memo(ContextProviders);
