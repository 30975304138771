// File: CustomTouchRipple.tsx
import { useImperativeHandle, forwardRef, useRef } from 'react';
import TouchRipple, { TouchRippleProps, TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';

const CustomTouchRipple = forwardRef<TouchRippleActions, TouchRippleProps>((props, ref) => {
  const rippleRef = useRef<TouchRippleActions>(null);

  useImperativeHandle(ref, () => ({
    start: (event, options) => rippleRef.current?.start(event, options),
    stop: () => rippleRef.current?.stop(),
    pulsate: () => rippleRef.current?.pulsate()
  }));

  return <TouchRipple ref={rippleRef} {...props} />;
});

CustomTouchRipple.displayName = 'CustomTouchRipple';

export default CustomTouchRipple;
