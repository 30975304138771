import { gql } from '@apollo/client';

export const CHATS_QUERY = gql`
  query Chats {
    chats
    @rest(
      type: "ChatsPayload",
      path: "chats"
    ) {
      data {
        id
        title
      }
    }
  }
`;
