import { memo, useMemo, useCallback, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
// Local
import style from './ShareLinkBtn.module.scss';

export type ShareLinkBtnAction = 'create' | 'copy' | 'copying' | 'copied';

type ShareLinkBtnProps = {
  action: ShareLinkBtnAction;
  onClick?: () => void;
  disabled?: boolean;
}

const ShareLinkBtnPropTypes = {
  action: PropTypes.string as Validator<ShareLinkBtnAction>,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

const ShareLinkBtn: FunctionComponent<ShareLinkBtnProps> = ({
  action = 'create',
  onClick,
  disabled
}) => {
  const { t } = useTranslation();
  const text = useMemo(() => {
    return {
      'create': 'share.dialog.btn.create',
      'copy': 'share.dialog.btn.copy',
      'copying': 'share.dialog.btn.copying',
      'copied': 'share.dialog.btn.copied'
    }[action];
  }, [action]);

  const handleClick = useCallback(() => onClick?.(), [onClick]);

  return (
    <Box className={style['container']}>
      <Button
        size="large"
        color="primary"
        disabled={disabled}
        startIcon={<InsertLinkOutlinedIcon/>}
        onClick={handleClick}
      >
        {t(text)}
      </Button>
    </Box>
  );
};

ShareLinkBtn.propTypes = ShareLinkBtnPropTypes;

export default memo(ShareLinkBtn);
