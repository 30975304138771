import { memo, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// Local
import { PATH_LOGIN } from '../../config/paths';
import style from './LoginBtn.module.scss';

type LoginBtnProps = {
  disabled?: boolean;
}

const LoginBtnPropTypes = {
  disabled: PropTypes.bool
};

const LoginBtn: FunctionComponent<LoginBtnProps> = ({
  disabled
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = useCallback(() => navigate(PATH_LOGIN), [navigate]);
  
  return (
    <Box className={style['container']}>
      <Button
        disabled={disabled}
        onClick={onClick}
      >
        {t('login')}
      </Button>
    </Box>
  );
};

LoginBtn.propTypes = LoginBtnPropTypes;

export default memo(LoginBtn);
