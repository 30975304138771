import { gql } from '@apollo/client';

export const SIGNUP_MUTATION = gql`
  mutation Signup($input: SignupInput!) {
    signup(input: $input)
    @rest(
      type: "SignupResponse",
      path: "auth/password/signup",
      method: "POST"
    ) {
      success
      data {
        user {
          id
          email
        }
        tokens {
          access_token
        }
      }
    }
  }
`;
