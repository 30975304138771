import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
// local
import style from './Markdown-light.module.css';

type MarkdownProps = {
  text?: string;
}

const MarkdownPropTypes = {
  text: PropTypes.string
};

const Markdown: FunctionComponent<MarkdownProps> = ({
  text
}) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={style['markdown-body']}
    >
      {text}
    </ReactMarkdown>
  );
};

Markdown.propTypes = MarkdownPropTypes;

export default memo(Markdown);
