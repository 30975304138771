import { gql } from '@apollo/client';

export const CREATE_FIRST_CHAT_MUTATION = gql`
  mutation CreateFirstChat(
    $input: CreateFirstChatInput!
  ) {
    createFirstChat(
      input: $input
    )
    @rest(
      type: "CreateFirstChatResponse",
      path: "chats/first",
      method: "POST"
    ) {
      data {
        id
        title
      }
    }
  }
`;
