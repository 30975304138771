import { memo, type FunctionComponent } from 'react';
// Local imports
import SignUp from '../components/home/Signup';

const SignupPage: FunctionComponent = () => {
  return (
    <SignUp/>
  );
}

export default memo(SignupPage);
