import size from 'lodash/size';
import { type AxiosRequestConfig } from 'axios';

export function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  window.location.assign('/');
}

export const getAuthToken = () => localStorage.getItem('token');
export const getRole = () => localStorage.getItem('role');

export const getAuthHeaders = () => ({
  headers: {
    Authorization: `Token ${getAuthToken()}`
  }
}) as AxiosRequestConfig;

export const isLoggedIn = () => Boolean(size(getAuthToken()));
