import { useMemo } from 'react';
import { type ApolloCache, type DefaultContext, type MutationFunctionOptions, type MutationTuple } from '@apollo/client';

function useMutationMethod<D extends object, V>({
  mutation
}: {
  key?: keyof D;
  mutation: MutationTuple<D, V>;
}) {
  const [mutateFunc, { loading, error, data }] = mutation;

  // catch any exceptions thrown by the mutation
  const mutate = useMemo(() => (options?: MutationFunctionOptions<D, V, DefaultContext, ApolloCache<unknown>>) => {
    (async () => {
      try {
        await mutateFunc(options);
      } catch (err) {
        // do nothing
      }
    })();
  }, [mutateFunc]);

  return {
    // mutate function
    mutate,
    // standard Apollo Client result
    loading,
    error,
    data,
    // our custom result
    failed: Boolean(error),
    succeeded: Boolean(!error && data)
  };
}

export default useMutationMethod;
