import { gql } from '@apollo/client';

export const SETTINGS_QUERY = gql`
  query Settings {
    settings
    @rest(
      type: "SettingsPayload",
      path: "settings"
    ) {
      data {
        scripture
      }
    }
  }
`;
