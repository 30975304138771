import { memo, useMemo, useState, useCallback, useRef, useEffect,
  type KeyboardEvent, type ChangeEvent, type MouseEvent, type FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toString from 'lodash/toString';
import size from 'lodash/size';
import clsx from  'clsx';
// MUI
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IosShareIcon from '@mui/icons-material/IosShare';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/material/styles';
// Local
import { PATH_CHATS, injectParams } from '../config/paths';
import style from './ChatButton.module.scss';

interface ChatButtonProps extends ButtonProps {
  chat?: string,
  page?: string; //current chat selected on the chat page
  onDeleteChat?: (chat: string) => void;
  onRenameChat?: (chat: string, name: string) => void;
  name?: string;
}

const ChatButton: FunctionComponent<ChatButtonProps> = ({
  chat,
  page,
  onDeleteChat,
  onRenameChat,
  name
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const selected = useMemo(() => toString(chat) === toString(page), [chat, page]);
  const rootRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [edit, setEdit] = useState(false);
  const renameRef = useRef<HTMLInputElement | null>(null);
  const [savedName, setSavedName] = useState(name);
  const [chatName, setChatName] = useState(name);

  useEffect(() => {
    setSavedName(name);
    setChatName(name);
  }, [name]);

  const {
    root, show: sshow, hide, selected: sselected,
    default: sdefault, page: spage, menu, menushow,
    menuhide, fading
  } = style;

  const handleClick = useCallback(() => {
    if (chat) navigate(injectParams(PATH_CHATS, {chatId: chat}));
  }, [chat, navigate]);

  const handleMenuBtnClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMenuClose = useCallback((event: any) => {
    function isMouseEventInElement(element: HTMLElement, event: MouseEvent): boolean {
        const rect = element.getBoundingClientRect();
        const x = event.clientX;
        const y = event.clientY;
        return (
            x >= rect.left &&
            x <= rect.right &&
            y >= rect.top &&
            y <= rect.bottom
        );
    }
    const element = rootRef.current;
    if (!(element && isMouseEventInElement(element, event))) {
      setShow(false);
    }
    setAnchor(null);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDeleteChat = useCallback((event: any) => {
    handleMenuClose(event);
    if(chat) onDeleteChat?.(chat);
  }, [chat, onDeleteChat, handleMenuClose]);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    if(edit) {
      interval.current = setInterval(() => {renameRef?.current?.focus()}, 100);
      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };
    }
    return undefined;
  }, [edit]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRenameChat = useCallback((event: any) => {
    handleMenuClose(event);
    setEdit(true);
  }, [handleMenuClose]);

  const handleNameChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setChatName(toString(event.target.value));
  }, []);

  const handleEscape = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setChatName(savedName);
      setEdit(false);
    }
  }, [savedName]);

  const handleRenamelKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setEdit(false);
    }
  }, []);

  const handleBlur = useCallback(() => {
    if (toString(savedName) !== toString(chatName)) {
      let newName = chatName;
      if (size(chatName) < 1) {
        newName = toString(t('chat.noname'));
        setChatName(newName);
      } else {
        newName = chatName;
      }
      if (chat) {
        onRenameChat?.(chat, toString(newName));
        setSavedName(newName);
      }
    }
    setEdit(false);
  }, [t, chat, savedName, chatName, onRenameChat]);

  const open = Boolean(anchor);

  return (
    <>
      <Box
        ref={rootRef}
        display='flex'
        alignItems='center'
        onMouseOver={() => setShow(true)}
        onMouseOut={() => setShow(false)}
        className={root}
      >
        <TextField
          className={edit ? sshow : hide}
          fullWidth
          size='small'
          inputRef={renameRef}
          autoComplete='off'
          value={chatName}
          onKeyPress={handleRenamelKeyPress}
          onKeyDown={handleEscape}
          onChange={handleNameChange}
          onBlur={handleBlur}
          sx={{ backgroundColor: '#fafafa' }}
        />
        <Box className={
            clsx(
              (show || selected || open) ? sselected : sdefault,
              (edit ? hide : sshow)
            )
          }
        >
          <Button
            onClick={handleClick}
            className={spage}
            disableRipple
          >
            <span className={fading}>
              {chatName}
            </span>
          </Button>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className={clsx(menu, (show || selected || open) ? menushow : menuhide)}
            onClick={handleMenuBtnClick}
            disableRipple
          >
            {open
              ? <ArrowDropDownIcon sx={{ color: theme.palette.grey[600] }}/>
              : <MoreVertIcon sx={{ color: theme.palette.grey[600] }}/>
            }
          </Button>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleMenuClose} disabled>
          <ListItemIcon>
            <IosShareIcon/>
          </ListItemIcon>
          <ListItemText>
            {t('drawer.chat.menu.share')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleRenameChat}>
          <ListItemIcon>
            <DriveFileRenameOutlineIcon/>
          </ListItemIcon>
          <ListItemText>
            {t('drawer.chat.menu.rename')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose} disabled>
          <ListItemIcon>
            <ArchiveOutlinedIcon/>
          </ListItemIcon>
          <ListItemText>
            {t('drawer.chat.menu.arhive')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteChat} sx={{ color: theme.palette.warning.main }}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon sx={{ color: theme.palette.warning.main }}/>
          </ListItemIcon>
          <ListItemText>
            {t('drawer.chat.menu.delete')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(ChatButton);
