import { memo, type FunctionComponent } from 'react';
// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
// Local

const LoadingMsgPlaceholder: FunctionComponent = () => {
  return (
    <Grid
      container
      sx={{m: 0, p: 0}}>
      <Grid item xs={8} sx={{p: '0.2rem'}}>
        <Box sx={{width: '100%', height: '1.2rem', backgroundColor: 'rgba(0 0 0 / 10%)', m: '0.2rem'}}/>
        <Box sx={{width: '100%', height: '1.2rem', backgroundColor: 'rgba(0 0 0 / 10%)', m: '0.2rem'}}/>
        <Box sx={{width: '100%', height: '1.2rem', backgroundColor: 'rgba(0 0 0 / 10%)', m: '0.2rem'}}/>
        <Box sx={{width: '100%', height: '1.2rem', backgroundColor: 'rgba(0 0 0 / 10%)', m: '0.2rem'}}/>
        <Skeleton variant="rectangular" height={'1.2rem'} sx={{width: '90%', m: '0.2rem'}}/>
      </Grid>
      <Grid item xs={4} sx={{p: '0.2rem'}}>
        <Box sx={{width: '100%', height: '1.2rem', backgroundColor: 'rgba(0 0 0 / 10%)', m: '0.2rem'}}/>
        <Box sx={{width: '100%', height: '1.2rem', backgroundColor: 'rgba(0 0 0 / 10%)', m: '0.2rem'}}/>
        <Box sx={{width: '100%', height: '1.2rem', backgroundColor: 'rgba(0 0 0 / 10%)', m: '0.2rem'}}/>
        <Box sx={{width: '100%', height: '1.2rem', backgroundColor: 'rgba(0 0 0 / 10%)', m: '0.2rem'}}/>
      </Grid>
    </Grid>
  );
};

export default memo(LoadingMsgPlaceholder);
