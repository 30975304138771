import { memo, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { styled } from '@mui/material/styles';
// Local
import logo from '../images/biblum-logo.jpg';

const GrayscaleImage = styled('img')({
  filter: 'grayscale(100%)',
});

const NoHistoryBanner: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      sx={{
        flexShrink: 0,
        width: '100%',
        height: '100%',
        pt: '64px'
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        component={Paper}
        elevation={0}
        sx={{
          width: '15rem',
          height: '8rem',
          border: 1,
          borderColor: '#fafafa'
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          {/*<AutoStoriesIcon sx={{ color: 'grey.500' }}/>*/}
          <GrayscaleImage src={logo} width='40rem'/>
          <Typography variant="h6" component="div" ml={1} color={'grey.500'}>
            {t('app.name')}
          </Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Typography variant="body1" component="div" color={'grey.500'} align="center">
            {t('app.description')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(NoHistoryBanner);
