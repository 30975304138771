import { memo, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
// Local

const FetchFailedAlert: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%' }}>
      <Alert severity="error">{t('error.fetch_failed')}</Alert>
    </Box>
  );
};

export default memo(FetchFailedAlert);
