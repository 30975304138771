import { gql } from '@apollo/client';

export const CHAT_HISTORY_QUERY = gql`
  query ChatHistory(
    $chat: String!
  ) {
    chatHistory(
      chat: $chat
    )
    @rest(
      type: "ChatHistoryPayload",
      path: "chats/{args.chat}/messages"
    ) {
      data {
        id
        chat_id
        text
        type
        sender
        status
        verses {
          id
          number
          text
          chapter {
            id
            number
          }
          book {
            id
            title
          }
        }
      }
    }
  }
`;
