import { gql } from '@apollo/client';

export const CONFIRM_EMAIL_MUTATION = gql`
  mutation ConfirmEmail(
    $input: ConfirmEmailInput!
  ) {
    confirmEmail(
      input: $input
    )
    @rest(
      type: "ConfirmEmailPayload",
      path: "accounts/confirm_email",
      method: "POST"
    ) {
      success
      code
      data {
        id
        username
        email
        auth_token
        error_code
      }
    }
  }
`;
