import { memo, useEffect, useState, useCallback, useRef,
  type KeyboardEvent, type MouseEvent, type ChangeEvent, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import toString from 'lodash/toString';
import size from 'lodash/size';
// MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
// Local
import { cache as appCache } from '../../graphql/cache';
import { PATH_HOME, PATH_CHAT, PATH_SIGNUP } from '../../config/paths';
import ActionFailedAlert from '../../components/ActionFailedAlert';
import useMutationMethod from '../../hooks/useMutationMethod';
import { LOGIN_MUTATION } from '../../graphql/Login';
import { LoginMutation, LoginDocument } from '../../graphql/types';
import logoImage from '../../images/biblum-logo-transparent-1.png';

type LoginProps = {
  testLogin?: boolean;
}

const LoginPropTypes = {
  testLogin: PropTypes.bool
};

const Login: FunctionComponent<LoginProps> = ({
  testLogin
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const pwdRef = useRef<HTMLInputElement | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { mutate: login, loading, failed } = useMutationMethod({
    mutation: useMutation(LOGIN_MUTATION as typeof LoginDocument)
  });

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), []);

  const handleMouseDownPassword = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const handleEmailChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setEmail(toString(event.target.value));
  }, []);

  const handlePasswordChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event.preventDefault();
    setPassword(toString(event.target.value));
  }, []);

  const handleSubmit = useCallback(() => {
    if(size(email) && size(password) && login) {
      setLoginFailed(false);
      login({
        variables: { input: { username_email: email, password } },
        onCompleted: (data: LoginMutation) => {
          const token = data.login.data.auth_token
          if (token) {
            localStorage.setItem('token', token);
            localStorage.setItem('role', 'user');
            appCache.evict({ id: 'ROOT_QUERY', fieldName: 'chats' });
            appCache.evict({ id: 'ROOT_QUERY', fieldName: 'settings' });
            setToken(token);
          } else {
            setLoginFailed(true);
          }
        }
      });
    }
  }, [email, password, login]);

  const handleEmailKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      pwdRef?.current?.focus();
    }
  }, []);

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);

  useEffect(() => {
    if(testLogin) login?.({ variables: { input: { username_email: email, password } } });
  }, [testLogin, email, password, login]);


  useEffect(() => {
    if (size(token) && !loading && !failed)
      return navigate(PATH_CHAT, { state: state });
  }, [token, loading, failed, state, navigate]);

  const toSignUp = useCallback(() => {
    navigate(PATH_SIGNUP, { state: state });
  }, [state, navigate]);

  const toHome = useCallback(() => {
    navigate(PATH_HOME, { state: state });
  }, [state, navigate]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{width: '25rem', mx: 'auto', overflowY: 'auto'}}
    >
      <Box display='flex' justifyContent='center' py={'4rem'}>
        <Link
          onClick={toHome}
          rel="noopener noreferrer"
          underline='none'
          sx={{cursor: 'pointer'}}
        >
          <img src={logoImage} width='40rem'/>
        </Link>
      </Box>
      <Box display='flex' justifyContent='center' pb={'1rem'}>
        <Typography variant='h5' component='div' color={'rgba(0 0 0 / 70%)'}>
          {t('login.title')}
        </Typography>
      </Box>
      <FormControl component='form'>
        <Box pb={'1rem'}>
          <TextField
            fullWidth
            size='small'
            type="email"
            label={t('email')}
            autoComplete='off'
            onChange={handleEmailChange}
            onKeyPress={handleEmailKeyPress}
            disabled={loading}
          />
        </Box>
        <Box pb={'1rem'}>
          <TextField
            fullWidth
            size='small'
            label={t('password')}
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            onChange={handlePasswordChange}
            onKeyPress={handleKeyPress}
            inputRef={pwdRef}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={handleSubmit}
          endIcon={loading ? <CircularProgress color="inherit" size="1.2rem"/> : undefined}
          disabled={!(size(email) && size(password)) || loading}
          sx={{textTransform: 'none', fontSize: '1rem'}}
        >
          {t('login')}
        </Button>
        <Box
          display='flex'
          justifyContent='center'
          py={'2rem'}
        >
          <Box display='flex' alignItems='center'>
            {t('login.to.signup')}
            <Link
              onClick={toSignUp}
              rel='noopener noreferrer'
              underline='none'
              pl='0.5rem'
              sx={{cursor: 'pointer'}}
            >
              {t('signup')}
            </Link>
          </Box>
        </Box>
        <ActionFailedAlert
          open={failed || loginFailed}
          message="login.error"
        />
      </FormControl>
    </Box>
  );
};

Login.propTypes = LoginPropTypes;

export default memo(Login);
