import { gql } from '@apollo/client';

export const CREATE_SHARED_LINK_MUTATION = gql`
  mutation CreateSharedLink(
    $chat: String!
    $input: SharedLinkInput!
  ) {
    createSharedLink(
      chat: $chat
      input: $input
    )
    @rest(
      type: "SharedLinkResponse",
      path: "chats/{args.chat}/share",
      method: "POST"
    ) {
      data {
        id
      }
    }
  }
`;
