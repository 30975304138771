import { memo, useCallback, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import size from 'lodash/size';
// MUI
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
// Local
import { type MessageRefs, RippleRef } from './types';
import { ChatMessage } from '../graphql/types';
import ShortcutButton from './ShortcutButton';

type ListOldQuestionsProps = {
  history?: ChatMessage[];
  itemRefs?: MessageRefs;
}

const ListOldQuestionsPropTypes = {
  history: PropTypes.array as Validator<ChatMessage[]>,
  itemRefs: PropTypes.object as Validator<MessageRefs>
};

const ListOldQuestions: FunctionComponent<ListOldQuestionsProps> = ({
  history,
  itemRefs
}) => {
  const { t } = useTranslation();

  const scrollToItem = useCallback((id: number) => {
    const item = itemRefs?.current?.[id]?.current as HTMLLIElement;
    item?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [itemRefs]);

  return (
    <>
      {size(history) > 0 && (
        <>
          <ListItem key={'header'} sx={{ pb: 0, pt: 2 }}>
            <Box
              fontSize={'0.9rem'}
              fontWeight={'500'}
              color={'#616161'}
              pb={1}
            >
              {t('drawer.shortcuts.label')}
            </Box>
          </ListItem>
          {map(history, (item, index) => (
            (item.sender !== 'Bot') && (
              <ListItem key={index} sx={{ pb: 0, pt: 0 }}>
                <ShortcutButton
                  onClick={() => scrollToItem(index)}
                  rippleRef={itemRefs?.current?.[`ripple${index}`] as RippleRef}
                >
                  {item.text}
                </ShortcutButton>
              </ListItem>
            )
          ))}
        </>
      )}
    </>
  );
};

ListOldQuestions.propTypes = ListOldQuestionsPropTypes;

export default memo(ListOldQuestions);
