import trim from 'lodash/trim';
import replace from 'lodash/replace';
import toLower from 'lodash/toLower';
import toString from 'lodash/toString';

// encode URL param for API call
export function encodeURLParam(value?: string | number | null): string {
  return encodeURIComponent(toLower(trim(toString(value))));
}

// inject parameters to path string
const injectRegexp = /:(\w+)/gu;
export const injectParams = (path: string, params: Record<string, string | number | null | undefined>) => replace(
  path, injectRegexp, (_match, p1) => encodeURLParam(params[p1])
);

export const PATH_ROOT = '/' as const;
export const PATH_HOME = '/' as const;
export const PATH_LOGIN = '/u/login' as const;
export const PATH_SIGNUP = '/u/signup' as const;
export const PATH_CHAT = '/c' as const;
export const PATH_CHATS = '/c/:chatId' as const;
export const PATH_RELATED_VERSES = '/c/:chatId/v/:verseId' as const;
export const PATH_CONFIRM_EMAIL = '/confirm-email' as const;
export const PATH_SHARED_CHAT = '/c/:chatId/s/:shareId' as const;
