import { useRef, useEffect, ReactNode, type RefObject, type FunctionComponent } from 'react';
// MUI
import { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
// Local
import FadingTextButton from './FadingTextButton';

const ShortcutBtn = styled(FadingTextButton)({
  display: 'flex',
  justifyContent: 'flex-start',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
  textTransform: 'none',
  alignItems: 'left',
  textAlign: 'left',
  fontSize: '1rem',
  fontWeight: '400',
  padding: '6px 6px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#fafafa',
  borderColor: '#fafafa',
  color: 'rgba(0, 0, 0, 0.87)',
  minWidth: '16rem',
  '&:hover': {
    backgroundColor: '#e0e0e0',
    borderColor: '#e0e0e0',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#e0e0e0',
    borderColor: '#e0e0e0',
  },
  '&:focus': {
    boxShadow: 'none'
  }
});

interface ShortcutButtonProps extends ButtonProps {
  rippleRef?: RefObject<TouchRippleActions>;
  children?: ReactNode | ReactNode[];
}

const ShortcutButton: FunctionComponent<ShortcutButtonProps> = ({
  rippleRef,
  children,
  ...props
}) => {
  const btnItemRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const btnItemElement = btnItemRef?.current;

    const handleMouseDown = (event: MouseEvent) => {
      if (rippleRef?.current) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rippleRef.current.start(event as any);
      }
    };
    const handleMouseUp = () => {
      if (rippleRef?.current) {
        rippleRef.current.stop();
      }
    };

    if (btnItemElement) {
      btnItemElement.addEventListener('mousedown', handleMouseDown);
      btnItemElement.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (btnItemElement) {
        btnItemElement.removeEventListener('mousedown', handleMouseDown);
        btnItemElement.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [rippleRef]);

  return (
    <ShortcutBtn ref={btnItemRef} {...props}>
      {children}
    </ShortcutBtn>
  );
};

export default ShortcutButton;
