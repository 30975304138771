import { gql } from '@apollo/client';

export const HISTORICAL_BACKGROUND_QUERY = gql`
  query HistoricalBackground(
    $verse: String!
  ) {
    historicalBackground(
      verse: $verse
    )
    @rest(
      type: "HistoricalBackgroundPayload",
      path: "verses/{args.verse}/historical_background"
    ) {
      data {
        text
      }
    }
  }
`;
