import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// MUI
import IconButton from '@mui/material/IconButton';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CircularProgress from '@mui/material/CircularProgress';
// Local

type InputBtnProps = {
  onClick?: () => void;
  pending?: boolean;
  disabled?: boolean;
}

const InputBtnPropTypes = {
  onClick: PropTypes.func,
  pending: PropTypes.bool,
  disabled: PropTypes.bool
};

const InputBtn: FunctionComponent<InputBtnProps> = ({
  onClick,
  pending,
  disabled
}) => {
  return (
    <IconButton
      size="small"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      sx={{ border: 1 }}
      edge="end"
    >
      {pending ? <CircularProgress color="inherit" size="1.5rem"/> : <ArrowForwardOutlinedIcon/>}
    </IconButton>
  );
};

InputBtn.propTypes = InputBtnPropTypes;

export default memo(InputBtn);
